// const baseURL = 'http://192.168.68.114:8000';
// const baseURL = 'https://api.dev.al-compliance.atomicloops.link';
const baseURL = 'https://xoacp0edfb.execute-api.ap-south-1.amazonaws.com/dev';
// const baseURL = 'http://localhost:8080';

export const endpoints = {
  login: `${baseURL}/login/`,
  refresh: `${baseURL}/refresh/`,
  users: `${baseURL}/users/`,
  logout: `${baseURL}/logout/`,
  ask: `${baseURL}/ask-with-context/`,
  askKuma:`${baseURL}/kuma-ask-with-context/`
};
