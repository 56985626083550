import React from "react";
import styles from "./Tooltip.module.css";

const Tooltip = ({ children, position = "top" }) => {
  return (
    <div className={styles.tooltipContainer}>
      <div className={`${styles.tooltip} ${styles[position]}`}>{children}</div>
    </div>
  );
};

export default Tooltip;
