export const markdownToPlainText = (text) => {
  let plainText = text;

  // Handle bold and italic (e.g., **bold**, __bold__, *italic*, _italic_)
  plainText = plainText.replace(/(\*\*|__)(.*?)\1/g, "$2"); // bold
  plainText = plainText.replace(/(\*|_)(.*?)\1/g, "$2"); // italic

  // Handle headings (e.g., ### Heading)
  plainText = plainText.replace(/^\s*(#+)\s+(.*)/gm, "$2"); // headings

  // Handle inline code (e.g., `code`)
  plainText = plainText.replace(/`([^`]*)`/g, "$1"); // inline code

  // Handle strikethrough (e.g., ~~strikethrough~~)
  plainText = plainText.replace(/~~(.*?)~~/g, "$1"); // strikethrough

  // Handle blockquotes (e.g., > Quote)
  plainText = plainText.replace(/^\s*>\s+(.*)/gm, "$1"); // blockquotes

  // Handle ordered lists (e.g., 1. Item)
  plainText = plainText.replace(/^\s*\d+\.\s+(.*)/gm, "$1"); // ordered lists

  // Handle unordered lists (e.g., * Item, - Item, + Item)
  plainText = plainText.replace(/^\s*[-+*]\s+(.*)/gm, "$1"); // unordered lists

  // Handle links (e.g., [text](url))
  plainText = plainText.replace(/\[([^\]]+)\]\([^)]+\)/g, "$1"); // links

  // Handle images (e.g., ![alt text](image_url))
  plainText = plainText.replace(/!\[([^\]]*)\]\([^)]+\)/g, "$1"); // images

  // Handle horizontal rules (e.g., --- or ***)
  plainText = plainText.replace(/^\s*(---|\*\*\*)\s*$/gm, ""); // horizontal rules

  // Trim leading and trailing whitespace
  return plainText.trim();
};
