import React, { useEffect, useRef, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import audiowaveGif from "../assets/sound-8825_256.gif";
import aiwavegif from "../assets/sound.gif";

import { useSearchParams } from "react-router-dom";
import { markdownToPlainText } from "../utils/markDownToPlainText";
import styles from "./AudioSectionPopup.module.css";
import Tooltip from "./Tooltip";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const AudioSectionPopup = ({
  onClosePopup,
  setInputPrompt,
  handleSubmit,
  responseFromAPI,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRecording, setIsRecording] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isAiSpeaking, setIsAiSpeaking] = useState(false);
  const recognitionRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const synthRef = useRef(window.speechSynthesis);
  const utteranceRef = useRef(null);

  useEffect(() => {
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        const transcriptResult = event.results[0][0].transcript;
        setInputPrompt(transcriptResult);
      };

      recognition.onerror = (event) => {
        console.error("Error occurred in recognition:", event.error);
        setIsRecording(false);
      };
      recognitionRef.current = recognition;
    }
  }, [setInputPrompt]);
  const handleRecord = async (e) => {
    e.preventDefault();
    if (isRecording) {
      stopRecording();
      const response = await handleSubmit(e, true);
      getAiResponse(response);
    } else {
      startRecording();
    }
  };

  const startRecording = async () => {
    setIsRecording(true);

    if (recognitionRef.current) {
      recognitionRef.current.start();
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
      };

      mediaRecorderRef.current.start();
    } catch (err) {
      console.error("Error accessing microphone: ", err);
    }
  };

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsRecording(false);
    }
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const getAiResponse = (userSpeech) => {
    if (userSpeech) {
      speakResponse(userSpeech);
    } else {
      const defaultResponse = "I'm unable to understand you. Please try again.";
      speakResponse(defaultResponse);
    }
  };

  const speakResponse = (text) => {
    const plainText = markdownToPlainText(text);
    const synth = synthRef.current;
    const utterance = new SpeechSynthesisUtterance(plainText);

    const voices = synth.getVoices();
    const femaleVoice = voices.find(
      (voice) =>
        voice.name.includes("Google US English") ||
        voice.name.includes("Microsoft Mark - English (United States)")
    );
    if (femaleVoice) {
      utterance.voice = femaleVoice;
    }

    utteranceRef.current = utterance;

    setIsAiSpeaking(true);

    utterance.onstart = () => {
      setIsAiSpeaking(true);

      let r = setInterval(() => {
        if (!synth.speaking) {
          clearInterval(r);
        } else {
          synth.pause();
          setTimeout(() => {
            synth.resume();
          }, 100);
        }
      }, 14000);
    };

    utterance.onend = () => {
      setIsAiSpeaking(false);
    };

    synth.speak(utterance);
  };

  const stopSpeaking = () => {
    const synth = synthRef.current;
    if (synth.speaking) {
      synth.cancel();
      setIsAiSpeaking(false);
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "20px",
          }}
        >
          {openTooltip && (
            <Tooltip>
              {searchParams.get("scenario") === "1" ? (
                <>
                  User's Goals:
                  <ul
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <li>
                      Order a starter, a main course, and a dessert from the
                      menu.
                    </li>
                    <li>
                      Ask the waiter or waitress about any special dishes or
                      recommendations.
                    </li>
                    <li>
                      Use at least four of the target vocabulary words in the
                      conversation (starter/appetizer, main course, dessert,
                      bill/check).
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  User's Goals:
                  <ul
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <li>
                      Present the startup's business model and financials,
                      including valuation, revenue growth, and CAC.
                    </li>
                    <li>
                      Use at least five of the target vocabulary words in your
                      presentation (business model, exit strategy, profit
                      margin, LTV, burn rate).
                    </li>
                    <li>
                      Address concerns from the committee about the startup's
                      runway and market size.
                    </li>
                  </ul>
                </>
              )}
            </Tooltip>
          )}
        </div>
        <svg
          onMouseEnter={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            cursor: "pointer",
            width: "20px",
            height: "20px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
          />
        </svg>
        <svg
          onClick={() => {
            if (isAiSpeaking) {
              stopSpeaking();
              onClosePopup();
            }
            onClosePopup();
          }}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            width: "20px",
            height: "20px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <div className={styles.audioWave}>
          {!isRecording && !isAiSpeaking && !responseFromAPI && (
            <p
              style={{
                textAlign: "center",
                color: "#555",
                fontSize: "1.4rem",
                fontFamily: "'Poppins', sans-serif",
                letterSpacing: "1px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              Ready to talk? Click the button below and start speaking!
            </p>
          )}

          {responseFromAPI && (
            <PulseLoader
              cssOverride={{
                color: "#555",
                loading: true,
              }}
              color={"#555"}
              size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}

          {isRecording && <img src={audiowaveGif} alt="audiowave" />}

          {isAiSpeaking && (
            <img
              src={aiwavegif}
              alt="audiowave"
              style={{
                height: `148px`,
                width: `200px`,
                padding: `20px`,
                objectFit: "contain",
              }}
            />
          )}
        </div>
        {isRecording && (
          <div className={styles.recordingIndicator}>
            <span className={styles.recordingDot}></span> Recording...
          </div>
        )}
        {!isAiSpeaking && (
          <button className={styles.recordBtn} onClick={handleRecord}>
            {isRecording ? "Stop Recording" : "Start Recording"}
          </button>
        )}
        {isAiSpeaking && (
          <button className={styles.recordBtn} onClick={stopSpeaking}>
            Stop Speaking
          </button>
        )}
      </div>
    </div>
  );
};

export default AudioSectionPopup;
