import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import NavLinksContainer from "./NavLinksContainer";

const NavContent = ({ chatLog, setChatLog, setShowMenu }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleScenarioChange = (scenario) => {
    setSearchParams({ scenario });
    setShowMenu(false);
  };
  useEffect(() => {
    if (!searchParams.get("scenario")) {
      setSearchParams({ scenario: "1" });
    }
  }, [searchParams]);
  return (
    <>
      <div>
        <h3>Kuma</h3>
      </div>
      <div className="tabsScenario">
        <div
          onClick={() => handleScenarioChange("1")}
          className={`
          ${
            searchParams.get("scenario") === "1"
              ? "tabScenarioActive tabScenario"
              : "tabScenario"
          }
          `}
        >
          Scenario 1
        </div>
        <div
          onClick={() => handleScenarioChange("2")}
          className={`
          ${
            searchParams.get("scenario") === "2"
              ? "tabScenarioActive tabScenario"
              : "tabScenario"
          }
          `}
        >
          Scenario 2
        </div>
      </div>
      <div className="navPromptWrapper"></div>
      <NavLinksContainer chatLog={chatLog} setChatLog={setChatLog} />
    </>
  );
};

export default NavContent;
