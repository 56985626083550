import React from "react";
import { useSearchParams } from "react-router-dom";
import BotResponse from "./BotResponse";

const IntroSection = () => {
  const [searchParams] = useSearchParams();
  return (
    <div id="introsection">
      <h1>
        <BotResponse response=" - Scenario Context:" />
      </h1>
      {searchParams.get("scenario") === "1" ? (
        <>
          <h2>
            You are at the restaurant with a friend, and the waiter or waitress
            approaches to take your order. Your goal is to order a starter, a
            main course, and a dessert. You will also ask for the bill at the
            end of the meal.
          </h2>
          User's Goals:
          <ul>
            <li>
              Order a starter, a main course, and a dessert from the menu.
            </li>
            <li>
              Ask the waiter or waitress about any special dishes or
              recommendations.
            </li>
            <li>
              Use at least four of the target vocabulary words in the
              conversation (starter/appetizer, main course, dessert,
              bill/check).
            </li>
          </ul>
          Optional Bonus:
          <ul>
            <li>Leave a tip and thank the waiter or waitress.</li>
          </ul>
        </>
      ) : (
        <>
          <h2>
            You are presenting an investment opportunity to the VC firm's
            investment committee. Your goal is to walk the committee through the
            startup's valuation, business model, and growth projections. You
            will also need to discuss its customer acquisition costs, profit
            margins, and exit strategy.
          </h2>
          User's Goals:
          <ul>
            <li>
              Present the startup's business model and financials, including
              valuation, revenue growth, and CAC.
            </li>
            <li>
              Use at least five of the target vocabulary words in your
              presentation (business model, exit strategy, profit margin, LTV,
              burn rate).
            </li>
            <li>
              Address concerns from the committee about the startup's runway and
              market size.
            </li>
          </ul>
          Optional Bonus:
          <ul>
            <li>
              Make a final recommendation on whether to move forward with the
              investment or wait for more data.
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default IntroSection;
